import axios from "axios";

export default {
  created() {
    this.api = this.createApi();
  },
  data: () => ({
    api: null,
  }),
  methods: {
    createApi() {
      const me = this;
      console.log("create");
      console.log("process.env.VUE_APP_BASE_URL");
      console.log(process.env.VUE_APP_BASE_URL);
      let api = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL,
        timeout: 100000000,
      });
      api.interceptors.request.use((config) => me.callInterceptor(config));
      api.interceptors.response.use(
        (response) => response,
        (error) => me.errorInterceptor(error)
      );
      return api;
    },

    callInterceptor(config) {
      console.log("Interceptor");
      console.log(config);
      console.log("this.$keycloak");
      console.log(this.$keycloak);
      if (this.$keycloak) {
        config.headers = {
          bearerToken: this.$keycloak.token,
        };
        const accessToken = this.$cookies.get("accessToken");
        if (accessToken) {
          config.headers.accessToken = accessToken;
        }
      }
      // const sessionInfo = localStorage.getObject("sessionInfo");
      // if (!sessionInfo) return config;
      // if (!sessionInfo.KeyCloakParam) return config;
      // if (!sessionInfo.KeyCloakParam.KeyCloakToken) return config;
      // config.headers = {
      //   "x-functions-key": sessionInfo.FuncAppKey,
      //   common: {
      //     // can be common or any other method
      //     accessToken: sessionInfo.KeyCloakParam.KeyCloakToken.access_token,
      //   },
      // };
      return config;
    },

    errorInterceptor(error) {
      console.log("axios.interceptors error:");
      console.log("error");
      console.log(error);
      console.log("error.status");
      console.log(error.status);
      console.log("error.response");
      console.log(error.response);

      if (error.response && error.response.status == 403) {
        this.$alert({
          title: "Keine Berechtigung",
          text: "Sie haben keine Berechtigung für diese Anwendung.",
          dialogMaxWidth: 400,
          acceptText: "Schliessen",
        });
        return;
      }
      if (error.message && error.message == "Network Error") {
        this.showError(
          "Die Server-Schnittstelle ist nicht verfügbar. Bitte versuchen Sie es später erneut."
        );
        return;
      }
      this.showError(error);
      return;
      //return Promise.reject(error);
      // if (error.response && error.response.status) {
      //   console.log(error.response.status);
      //   if (error.response.status == 401 || error.response.status == 403) {
      //     this.refreshSessionOrLogin();
      //   } else if (error.response.status == 500) {
      //     this.showError(error);
      //     return;
      //   }
      // }
      //return Promise.reject(error);
    },

    showError(error) {
      console.log("showError");
      console.log(error.response);
      this.$alert({
        title: "Es ist ein Fehler aufgetreten",
        text: error,
        dialogMaxWidth: 400,
        acceptText: "Schliessen",
      });
    },

    async refreshSessionOrLogin() {},
  },
};
