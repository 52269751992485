import Vue from "vue";
import VueKeycloakJs from "@dsb-norge/vue-keycloak-js";
import VuetifyDialogPromise from "vuetify-dialog-promise";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueScreenSize from "vue-screen-size";
import VueCookies from "vue-cookies";
import "../styles.css";

Vue.config.productionTip = false;

Vue.use(VueCookies);

Vue.use(VuetifyDialogPromise, {
  locale: "de",
  snackbarX: "left",
  snackbarY: "bottom",
});

Vue.use(VueScreenSize);

Vue.use(VueKeycloakJs, {
  init: {
    // Use 'login-required' to always require authentication
    // If using 'login-required', there is no need for the router guards in router.js
    onLoad: "login-required", // login-required | check-sso'
    checkLoginIframe: false,
    //redirectUri: `${process.env.VUE_APP_CLIENT_URL}report/${process.env.VUE_APP_LIVE_REPORTID}`,
    //silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
  },
  onReady: function () {
    router.push(`/report/${process.env.VUE_APP_LIVE_REPORTID}`);
  },
  scope: ["openid", "roles"],
  config: {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
  },
}),
  new Vue({
    router,
    vuetify,
    render: function (h) {
      return h(App);
    },
  }).$mount("#app");
