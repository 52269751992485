<template>
  <v-container style="padding: 0px">
    <v-overlay opacity="0" v-show="isLoading == true" class="wait-screen">
      <v-progress-circular
        indeterminate
        size="64"
        color="red"
      ></v-progress-circular>
    </v-overlay>
    <div v-show="isLoading == false" :style="getStyle" ref="container"></div>
  </v-container>
</template>
<style scoped>
iframe {
  border: none;
}
.wait-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
<script>
import { models } from "powerbi-client";
import * as pbi from "powerbi-client";
import apiMixin from "@/mixins/apiMixin.js";
import tokenCookieMixin from "@/mixins/tokenCookieMixin.js";

export default {
  name: "simple-report-container",

  mixins: [apiMixin, tokenCookieMixin],

  props: {},

  data: () => ({
    isLoading: false,
    reportName: "",
    pageName: "",
    error: "",
    reportId: null,
    report: null,
  }),

  created() {},

  mounted() {
    this.reportId = this.$route.params.reportId;
    this.createAndInitReport();
  },

  watch: {
    $route: function () {
      this.reportId = this.$route.params.reportId;
      this.createAndInitReport();
    },
  },

  computed: {
    getStyle() {
      return `
      background-color:
      transparent;
      width:100%;
      display:flex;
      align-items:center;
      style="border: none;"
      justify-content: center;
      height:${this.reportContentHeight}px`;
    },

    reportContentHeight() {
      let height = parseInt(this.$vssHeight) - 110; //160 is the height of toolbar + footer + spaces
      return height;
    },
  },
  methods: {
    async createAndInitReport() {
      this.isLoading = true;
      const res = await this.api.get(`embeddedToken/${this.reportId}`);
      this.isLoading = false;
      if (res == null) return;
      this.setTokenCookie(res.data.accessToken);

      const reportLoadConfig = {
        type: "report",
        tokenType: models.TokenType.Embed,
        accessToken: res.data.embedToken.token,
        embedUrl: res.data.embedReports[0].embedUrl,
        pageName: this.pageName,
        settings: {
          background: models.BackgroundType.Transparent,
          panes: {
            filters: {
              visible: false,
            },
            pageNavigation: {
              visible: false,
            },
          },
        },
      };

      const reportContainer = this.$refs.container;

      let powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );

      powerbi.reset(reportContainer);
      this.report = powerbi.embed(reportContainer, reportLoadConfig);
    },
  },
};
</script>
