import Vue from "vue";
import VueRouter from "vue-router";
// import AppFrame from "../AppFrame.vue";
import ReportView from "../views/ReportView.vue";
import AboutView from "../views/AboutView.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   redirect: { path: `/report/${process.env.VUE_APP_LIVE_REPORTID}` },
  // This does not work in combination with vue-keycloak-js (results in permission error for user)
  // vue-keycloak-js intercepts the router request
  // seems to works in usual cases but not with redirect
  // },
  {
    path: "/report/:reportId",
    name: "ReportView",
    component: ReportView,
  },
  {
    path: "/about",
    name: "AboutView",
    component: AboutView,
  },
];

const router = new VueRouter({
  // mode: "history",
  // hash: true,
  routes: routes,
});

export default router;
