<template>
  <v-container>
    <simple-report-container></simple-report-container>
  </v-container>
</template>

<style></style>

<script>
import SimpleReportContainer from "@/components/SimpleReportContainer.vue";
import apiMixin from "@/mixins/apiMixin.js";
import tokenCookieMixin from "@/mixins/tokenCookieMixin.js";

export default {
  name: "ReportView",
  components: {
    SimpleReportContainer,
  },

  data: () => ({
    reportId: "",
    exportId: null,
  }),

  created() {
    this.reportId = this.$route.params.reportName;
  },

  mixins: [apiMixin, tokenCookieMixin],

  methods: {
    async showReport() {
      const res2 = await this.api.get(
        `export/getFile/${this.reportId}/${this.exportId}`,
        { responseType: "blob" }
      );

      const fileUrl = URL.createObjectURL(new Blob([res2.data]));

      // Open the PDF file in a new window or tab
      window.open(fileUrl);

      // Open the PDF in a new window
      window.open(fileUrl, "_blank");
    },

    downloadFile() {
      const link = document.createElement("a");
      link.href = this.invoicePDF;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
  },
};
</script>
