<template>
  <v-app>
    <v-navigation-drawer
      class="pa-4"
      app
      v-bind:width="240"
      mobile-breakpoint="600"
      color="#e0e2e7"
      v-model="showSideBar"
    >
      <v-row>
        <v-col>
          <router-link to="/">
            <v-img
              src="./logo.png"
              max-width="100"
              style="margin-left: 10px; margin-top: 10px"
            ></v-img>
          </router-link>
        </v-col>
        <v-col>
          <p style="margin-top: 15px; font-size: 12px">
            {{ appTitle }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <sideMenu></sideMenu>
      </v-row>
      <v-spacer></v-spacer>
      <div class="app-version">Version {{ appVersion }}</div>
    </v-navigation-drawer>

    <v-app-bar app ref="appBar">
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon
            v-bind="attrs"
            v-on="on"
            @click="showSideBar = !showSideBar"
          ></v-app-bar-nav-icon>
        </template>
        <span>Navigation ein- oder ausblenden</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="logout" color="primary" icon v-bind="attrs" v-on="on">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>Abmelden</span>
      </v-tooltip>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view ref="routerview"> </router-view>
      </v-container>
    </v-main>

    <!-- <v-footer app>
      <span class="px-4 footer">Version {{ versionNo }}</span>
    </v-footer> -->
  </v-app>
</template>

<style lang="scss">
html {
  overflow-y: auto;
}

/* to avoid unnecessary scrollbar on the right, see https://stackoverflow.com/questions/56973002/vuetify-adds-scrollbar-when-its-not-needed" */
.footer {
  font-size: 14px !important;
  color: #2196f3;
}

.app-version {
  position: absolute;
  bottom: 10px;
  /* Adjust the distance from the bottom as needed */
  width: 100%;
  font-size: smaller;
  text-align: left;
}
</style>

<script>
import sideMenu from "./components/SideMenu.vue";
export default {
  name: "App",
  components: {
    sideMenu,
  },
  data: () => ({
    showSideBar: true,
    appTitle: "Remote POS Managenment",
    appVersion: "0.9.3",
  }),

  created() {
    document.title = this.appTitle;
  },
  updated() {
    document.title = this.appTitle;
  },
  methods: {
    logout() {
      this.$keycloak.logoutFn();
    },
  },
};
</script>
