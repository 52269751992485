<template>
  <v-list nav dense class="px-5">
    <v-list-item-group v-model="selectedItem">
      <v-list-item link :to="`/report/${liveReportId}`">
        <v-list-item-icon class="me-3">
          <v-icon>mdi-timetable</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title style="width: 200px">LIVE</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link :to="`/report/${analysisReportId}`">
        <v-list-item-icon class="me-3">
          <v-icon>mdi-google-analytics</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title style="width: 200px">ANALYSIS</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link :to="`/report/${dealerReportId}`">
        <v-list-item-icon class="me-3">
          <v-icon>mdi-store</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title style="width: 200px"
            >DEALER REPORT</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<style scoped>
tr:hover {
  background-color: transparent !important;
}
</style>
<script>
export default {
  name: "SideMenu",

  data: () => ({
    selectedItem: null,
    liveReportId: process.env.VUE_APP_LIVE_REPORTID,
    analysisReportId: process.env.VUE_APP_ANALYSIS_REPORTID,
    dealerReportId: process.env.VUE_APP_DEALER_REPORTID,
  }),

  methods: {
    hasRole(roleName) {
      console.log(roleName);
      return true; //Only temporarily to test server side permission checks
    },
  },
};
</script>
