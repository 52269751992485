import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      //chrome: #525f6b #8f98a2  #e0e2e7
      //coffee: #522c00 #9a7348' #e7d9ca
      //red: #e30613
      light: {
        primary: "#525f6b",
        secondary: "#9a7348",
        accent: "#8c9eff",
        error: "#e30613",
      },
    },
  },
});
