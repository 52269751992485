export default {
  methods: {
    getExpirationTime(accessToken) {
      const tokenParts = accessToken.split(".");
      if (tokenParts.length === 3) {
        const payload = JSON.parse(atob(tokenParts[1]));
        if (payload && payload.exp) {
          // The expiration time is in seconds
          const expirationTimeInSeconds = payload.exp;
          return new Date(expirationTimeInSeconds * 1000);
        }
      }
      return null;
    },
    setTokenCookie(accessToken) {
      const expirationTime = this.getExpirationTime(accessToken);
      const cookieValue = `accessToken=${accessToken};expires=${expirationTime.toUTCString()};path=/;Secure;`;
      document.cookie = cookieValue;
    },
  },
};
